const skills = [
    {
        id: 1,
        title: 'HTML&CSS',
        text: 'Able to convert Mockup to responsive HTML and CSS writing maintainable CSS code.',
        icon: 'html'
    },
    {
        id: 2,
        title: 'JavaScript',
        text: 'Able to create dynamic web pages with modern ES6 features.',
        icon: 'javascript'
    },
    {
        id: 3,
        title: 'React',
        text: 'Able to build a modern component-based application using JSX.',
        icon: 'react'
    },
]

export default skills