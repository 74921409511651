import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5, faJs, faReact } from "@fortawesome/free-brands-svg-icons";
import skills from '../data/skills.js'

const Skills = () => {
  const getIcon = (iconName) => {
    switch(iconName) {
      case "html": return faHtml5;
      case "javascript": return faJs;
      case "react": return faReact;
      default: return faHtml5;
    }
  };

  return(
    <section className="section skills" id="skills">
      <div className="section-title">
        <h2>skills</h2>
        <div className="underline"></div>
      </div>

      <div className="section-center skills-center">
        {skills.map((skill)=>{
          const{id, text, title, icon} = skill;
          return(
            <article key={id} className='single-skill'>
              <span className="skill-icon">
                <FontAwesomeIcon icon={getIcon(icon)} />
              </span>
              <h4>{title}</h4>
              <p>{text}</p>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Skills