import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub, faBluesky } from "@fortawesome/free-brands-svg-icons";
import img from "../assets/images/webdeveloper.svg";
import socialLinks from "../data/social_links.js";

const Hero = () => {
  const getIcon = (iconName) => {
    switch(iconName) {
      case "linkedin": return faLinkedin;
      case "github": return faGithub;
      case "bluesky": return faBluesky;
      default: return faGithub;
    }
  };

  return (
    <header className="hero" id="home">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div className="underline"></div>
          <h1>Hey, i'm Farah.</h1>
          <h4>Web developer</h4>
          <a href="#about" className="btn hero-btn">
            about me
          </a>

          <ul className="social-icons hero-icons">
            {socialLinks.map((socialLink) => {
              const { id, url, icon } = socialLink;
              return (
                <li key={id}>
                  <a href={url} className="social-icon">
                    <FontAwesomeIcon icon={getIcon(icon)} />
                  </a>
                </li>
              );
            })}
          </ul>
        </article>
        <article className="hero-img">
          <img src={img} className="hero-photo" alt="Farah" />
        </article>
      </div>
    </header>
  );
};

export default Hero;
