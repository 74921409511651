const socialLinks = [
  {
    id: 1,
    url: "https://www.linkedin.com/in/farah-fournat/",
    icon: "linkedin",
  },
  {
    id: 2,
    url: "https://github.com/Farahcodes",
    icon: "github",
  },
  {
    id: 3,
    url: "https://bsky.app/profile/farahcodes.github.io",
    icon: "bluesky",
  },
];

export default socialLinks;
